import React, { useState, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, onSnapshot, query, getDocs, where, getDoc, doc, collectionGroup, updateDoc } from 'firebase/firestore';
import 'firebase/firestore';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { db } from './firebase';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CSVLink } from "react-csv";

const Users = () => {
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);
    const [users, loading, error] = useCollectionData(
        collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [onlineUserCount, setOnlineUserCount] = useState(0);
    const [userLogins, setUserLogins] = useState([]);
    let snapshotListener;

    const onGridReady = (params) => {
        setGridApi(params.api);
    };


    const columnDefs = [
        { headerName: 'Name', field: 'name', flex: 1, filter: 'agTextColumnFilter', },
        { headerName: 'Email', field: 'email', flex: 1, filter: true, },
        { headerName: 'Phone Number', field: 'phoneNumber', flex: 1, filter: true, },
        { headerName: 'Enabled', field: 'enable', flex: 1, editable: true, filter: true, },
        // { headerName: 'Paid', field: 'paid', flex: 1, editable: true, filter: true, },
    ];

    const columnDefsOnline = [
        { headerName: "Name", field: "name", flex: 1 },
        { headerName: "Online", field: "online", flex: 1 },
    ];
    const usersonline = [];
    useEffect(() => {
        async function fetchOnlineUsers() {
            try {
                const onlineUsersRef = collection(db, 'status');
                const q = query(onlineUsersRef, where('state', '==', 'online'));
                const querySnapshot = await getDocs(q);

                const userIds = querySnapshot.docs.map(doc => doc.id);
                const userDataPromises = userIds.map(async (userId) => {
                    const userQuery = query(collection(db, 'users'), where('uid', '==', userId));
                    const userQuerySnapshot = await getDocs(userQuery);
                    return userQuerySnapshot.docs.map(doc => doc.data());
                });

                const onlineUsersData = await Promise.all(userDataPromises);
                const onlineUsersFiltered = onlineUsersData.flat();
                setOnlineUsers(onlineUsersFiltered);

                async function getUserLogins() {
                    const querySnapshot = await getDocs(collectionGroup(db, 'logins'));
                    const userLogTime = querySnapshot.docs.map((doc) => {
                        const parentId = doc.ref.parent.parent.id;
                        const timeStamp = doc.data().timestamp.seconds;
                        return {
                            parentId: parentId,
                            timeInSeconds: timeStamp,
                        };
                    });

                    const today = new Date();
                    const formattedToday = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

                    const uniqueIdsPerDay = new Set();
                    const userNames = [];

                    userLogTime.forEach((item) => {
                        const date = new Date(item.timeInSeconds * 1000);
                        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

                        if (formattedDate === formattedToday) {
                            uniqueIdsPerDay.add(item.parentId);
                        }
                    });

                    const allUniqueIds = Array.from(uniqueIdsPerDay);

                    const userQueries = allUniqueIds.map((id) => {
                        return query(collection(db, "users"), where("uid", "==", id));
                    });

                    const querySnapshots = await Promise.all(userQueries.map(getDocs));

                    querySnapshots.forEach((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            userNames.push({ 'name': doc.data().name });
                        });
                    });
                    setUserLogins(userNames);
                }

                // Call the function to execute the code
                getUserLogins();

            } catch (error) {
                console.error('Error fetching online users:', error);
            }
        }

        fetchOnlineUsers();
    }, [refresh]);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleCellEditingStopped = async (event) => {
        const { data, colDef, newValue, rowIndex, column, api, node } = event;
        var docId;
        const q = query(collection(db, "users"), where("uid", "==", data.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            docId = doc.id;
        });
        await updateDoc(doc(db, "users", docId), { enable: newValue })
    };


    return (
        <>
            <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate("/home")} style={{ margin: '10px' }}>
                Back
            </Button>
            <CSVLink data={users}>
                Download Raw User data
            </CSVLink>
            <div style={{ backgroundColor: '#f5f5f5', border: '1px solid black', borderRadius: '5px', padding: '10px', marginBottom: '20px' }}>
                <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', }}>Total Online Users: {onlineUserCount}</div>
                <Button variant="contained" onClick={() => setRefresh(!refresh)}>Refresh Online Users</Button>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '0 0 500px' }}>
                        <h2>All users</h2>
                        <div className="ag-theme-alpine" style={{ height: '500px', width: '500px' }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={users}
                                pagination={true}
                                paginationPageSize={10}
                                onGridReady={onGridReady}
                                onCellEditingStopped={handleCellEditingStopped}
                            />
                        </div>
                    </div>
                    {/* <div style={{ marginLeft: '20px' }}>
                        <h2>Logged in Users Today</h2>
                        <div className="ag-theme-alpine" style={{ height: '500px', width: '500px' }}>
                            <AgGridReact
                                rowData={userLogins}
                                columnDefs={columnDefsOnline}
                                defaultColDef={{ sortable: true, filter: true }}
                            />
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <h2>Online users (Live)</h2>
                        <div className="ag-theme-alpine" style={{ height: '500px', width: '500px' }}>
                            <AgGridReact
                                rowData={onlineUsers}
                                columnDefs={columnDefsOnline}
                                defaultColDef={{ sortable: true, filter: true }}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </>


    );
};

export default Users;
