import React, { useState, useRef, useContext, useEffect } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, Grid, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, onSnapshot, doc, getDoc } from "firebase/firestore";
import MyContext from './MyContext';
import MyDialog from './MyDialog';
import { db, app } from './firebase';
import "./styles.css";
import { auth } from './firebase';
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MyDialogEdit from './MyDialogEdit';
import TabNavHome from './TabNavHome';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { rtdb_presence, rtdb_and_local_fs_presence } from './firebasePresence';
import TableViewIcon from '@mui/icons-material/TableView';
import PaymentDialog from './PaymentDialog';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import PaymentDialogPhonePe from './PaymentDialogPhonePe';

const Home = () => {
    const [user, loading, error] = useAuthState(auth);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const { sharedDataDate, sharedDataVenue } = useContext(MyContext);
    const [dateValue, setDateValue] = sharedDataDate;
    const [venue, setVenue] = sharedDataVenue;
    const [rowData, setRowData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();
    const [oddsDateValue, setOddsDateValue] = React.useState(dayjs(new Date()));
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [admin, setAdmin] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [updateData, setUpdateData] = useState();
    const [hasUserPaid, setHasUserPaid] = useState([]);
    const [index, setIndex] = useState();
    const [state, setState] = useState({});
    const [paymentsEnabled, setPaymentsEnabled] = useState(true);


    const fetchUserName = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            if (data.admin) {
                setAdmin(true);
            }
            const today = new Date();
            const transDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
            const transactionQuery = query(
                collection(db, "transactions" + transDate),
                where("userId", "==", user?.uid),
                where("featureAccessYear", "==", today.getFullYear()),
                where("featureAccessMonth", "==", today.getMonth() + 1), // JavaScript months are 0-11
                where("featureAccessDay", "==", today.getDate())
            );

            getDocs(transactionQuery)
                .then((querySnapshot) => {
                    const transactionsToday = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        if (data.status === "PAYMENT_SUCCESS") {
                            transactionsToday.push(data.features);
                        }
                    });
                    let splitElements = [];
                    transactionsToday.forEach(element => {
                        // Split each element by comma and add the resulting elements to the splitElements array
                        splitElements = splitElements.concat(element.split(','));
                    });

                    // Remove duplicates
                    let uniqueElements = Array.from(new Set(splitElements));

                    if (uniqueElements?.length > 0) {
                        setHasUserPaid({
                            paid: true,
                            odds: uniqueElements.includes("ODDS"),
                            selection: uniqueElements.includes("SELECTION"),
                            paddock: uniqueElements.includes("PADDOCK_OPINION"),
                            finalJudgeMent: uniqueElements.includes("FINAL_JUDGEMENT")
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error fetching documents: ", error);
                });

            var emails = ["anilkavali601@gmail.com", "jaykeerti123@gmail.com"]
            if (emails.includes(user.email)) {
                setSuperAdmin(true);
            }
            //rtdb_presence()
            //rtdb_and_local_fs_presence()
        } catch (err) {
            console.error(err);
            // alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) <CircularProgress />;
        // if (!user) return navigate("/login");
        if (user) {
            fetchUserName();
        }

        const getSwitchData = async () => {
            const firebaseDataName = "0buttonswitch";
            const defaultDocRef = doc(db, firebaseDataName, 'default');

            try {
                const docSnapshot = await getDoc(defaultDocRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setState(prevState => ({ ...prevState, ...data }));
                    // console.log('Document data:', data);
                } else {
                    // console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        }

        getSwitchData();

    }, [user, loading]);

    const formatDateForQuery = (date) => {
        return date ? dayjs(date).format('DDMMYYYY') : '';
    };


    useEffect(() => {
        const getSwitchData = async () => {
            const firebaseDataName = "paymentsEnabled" + dayjs(new Date()).format('DDMMYYYY');
            const defaultDocRef = doc(db, firebaseDataName, 'default');

            try {
                const docSnapshot = await getDoc(defaultDocRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setPaymentsEnabled(prevState => (data.paymentsEnabled));
                    // console.log('Document data:', data);
                } else {
                    // console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        }

        getSwitchData();
    }, [])



    const handleClick = ({ venue, oddsDateValue }, link, isFinalJudgeMent = null) => {
        setDateValue(oddsDateValue);
        setVenue(venue);
        navigate("/tabs", { state: { admin: admin, link: link, isFinalJudgeMent: isFinalJudgeMent } });
    };

    const buttonStyle = {
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#ffcc00', // Base gold color
        borderRadius: '20px',
        border: 'none',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        color: '#fff',
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        cursor: 'pointer',
        outline: 'none',
        padding: '10px 20px',
        margin: '1px 5px',
        backgroundImage: 'linear-gradient(to right, #ffcc00, #ff9900)', // Gold gradient
    };

    // Inline styles for the shine effect
    const shineStyle = {
        content: '',
        position: 'absolute',
        top: '0',
        left: '-75px', // start the shine off of the button
        width: '50px', // width of the shine
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)', // white shine with opacity
        transform: 'skewX(-20deg)',
        transition: 'left 0.7s',
    };

    // Inline styles for the button container to handle the shine's hover effect
    const buttonContainerStyle = {
        position: 'relative',
        display: 'inline-block', // Allows the overflow property to take effect
    };


    const LinkRenderer = (params) => {
        const today = new Date();
        const todayFormatted = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
        const isToday = params.data.currDate === todayFormatted;
        if (paymentsEnabled && isToday && !hasUserPaid?.odds && !admin && state?.switch1) {
            return (
                <div style={buttonContainerStyle}>
                    <Button style={buttonStyle} onClick={() => handleDialogOpen(1)} variant='contained'>
                        Pay Now
                        <span style={shineStyle}></span>
                    </Button>
                </div>
            );
        } else {
            return state?.switch1 ? <Button size="small" onClick={() => handleClick(params.data, "odds")} variant='contained'> View ODDS </Button> : <></>;
        }
    };


    const LinkRendererPaddock = (params) => {
        const today = new Date();
        const todayFormatted = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
        const isToday = params.data.currDate === todayFormatted;
        if (paymentsEnabled && isToday && !hasUserPaid?.paddock && !admin && !superAdmin && state?.switch3) {
            return (
                <div style={buttonContainerStyle}>
                    <Button style={buttonStyle} onClick={() => handleDialogOpen(0)} variant='contained'>
                        Pay Now
                        <span style={shineStyle}></span>
                    </Button>
                </div>
            );
        } else {
            return (superAdmin ? <Button size="small" onClick={() => handleClick(params.data, "paddock")} variant='contained' > Paddock Opinion </Button> : admin ? '' : state?.switch3 ? <Button size="small" onClick={() => handleClick(params.data, "paddock")} variant='contained' > Paddock Opinion </Button> : <></>);
        }

    };

    const LinkRendererFinalJudgement = (params) => {
        const today = new Date();
        const todayFormatted = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
        const isToday = params.data.currDate === todayFormatted;
        if (paymentsEnabled && isToday && !hasUserPaid?.finalJudgeMent && !admin && !superAdmin && state?.switch4) {
            return (
                <div style={buttonContainerStyle}>
                    <Button style={buttonStyle} onClick={() => handleDialogOpen(3)} variant='contained'>
                        Pay Now
                        <span style={shineStyle}></span>
                    </Button>
                </div>
            );
        } else {
            return (superAdmin ? <Button size="small" onClick={() => handleClick(params.data, "selection", true)} variant='contained' >Final Judgement</Button> : admin ? '' : state?.switch4 ? <Button size="small" onClick={() => handleClick(params.data, "selection", true)} variant='contained' > Final Judgement </Button> : <></>);
        }

    };

    const LinkRendererSelection = (params) => {
        const today = new Date();
        const todayFormatted = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
        const isToday = params.data.currDate === todayFormatted;
        if (paymentsEnabled && isToday && !hasUserPaid?.selection && !admin && !superAdmin && state?.switch2) {
            return (
                <div style={buttonContainerStyle}>
                    <Button style={buttonStyle} onClick={() => handleDialogOpen(2)} variant='contained'>
                        Pay Now
                        <span style={shineStyle}></span>
                    </Button>
                </div>
            );
        } else {
            return (superAdmin ? <Button size="small" onClick={() => handleClick(params.data, "selection")} variant='contained'>  Selection </Button> : admin ? '' : state?.switch2 ? <Button size="small" onClick={() => handleClick(params.data, "selection")} variant='contained'>  Selection </Button> : <></>);
        }

    };


    const LinkRendererPercentage = (params) => {
        return (
            admin ? (<Button size="small" onClick={() => handleClick(params.data, "percentage")} variant='contained' > Percentage </Button >) : ""

        );
    }

    useEffect(() => {
        const fetchOddsData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "oddsDetails"));
                let fbData = [];
                querySnapshot.docs.map((doc) => {
                    fbData.push({ id: doc.id, ...doc.data() });
                });
                fbData.sort((a, b) => {
                    const dateA = new Date(a.currDate.split('/').reverse().join('/'));
                    const dateB = new Date(b.currDate.split('/').reverse().join('/'));
                    return dateB - dateA;
                });
                setRowData(fbData);
            } catch (err) {
                console.error(err);
                alert("An error occured while fetching user data");
            }
        }
        fetchOddsData();
    }, [isDialogOpen, isEditDialogOpen]);

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    const toggleDialogEdit = (params) => {
        setUpdateData(params?.data)
        setIsEditDialogOpen(!isEditDialogOpen);

    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDialogClose = () => {
        handleClose();
    };

    const columnDefs = [
        {
            headerName: 'Actions',
            field: 'actions',
            width: 50,
            cellRendererFramework: (params) => {
                return <>
                    <MyDialogEdit open={isEditDialogOpen} onClose={toggleDialogEdit} cellValues={updateData} />
                    <EditIcon style={{ width: '15px', height: '15px' }} onClick={() => toggleDialogEdit(params)} />
                </>
            },
        },
        {
            headerName: 'Date',
            field: 'currDate',
        },
        { headerName: '', field: 'venue', width: 100 },

        { headerName: 'odds', field: 'oddsDateValue', cellRendererFramework: LinkRenderer },
        { headerName: 'Selection', field: 'oddsDateValue', cellRendererFramework: LinkRendererSelection, width: 100 },
        { headerName: 'Paddock Opinion', field: 'oddsDateValue', cellRendererFramework: LinkRendererPaddock, width: 150 },
        { headerName: 'Final Judgement', field: 'oddsDateValue', cellRendererFramework: LinkRendererFinalJudgement, width: 150 },
        { headerName: 'Percentage', field: 'oddsDateValue', cellRendererFramework: LinkRendererPercentage, width: 150 }

    ];

    const columnDefsUser = [
        {
            headerName: 'Date',
            field: 'currDate',
            width: 100,
            headerClass: 'custom-header-style',
        },
        {
            headerName: 'Venue',
            field: 'venue',
            width: 100,
            headerClass: 'custom-header-style'
        },
        {
            headerName: 'odds',
            field: 'oddsDateValue',
            cellRendererFramework: LinkRenderer,
            width: 100,
            headerClass: 'custom-header-style',
        },
        {
            headerName: 'Selection',
            field: 'oddsDateValue',
            cellRendererFramework: LinkRendererSelection,
            width: 100,
            headerClass: 'custom-header-style',
        },
        {
            headerName: 'Paddock Opinion',
            field: 'oddsDateValue',
            cellRendererFramework: LinkRendererPaddock,
            width: 150,
            headerClass: 'custom-header-style',
        },
        {
            headerName: 'Final Judgement',
            field: 'oddsDateValue',
            cellRendererFramework: LinkRendererFinalJudgement,
            width: 150,
            headerClass: 'custom-header-style',
        }
    ];


    const handleSave = () => {
        setDateValue(dayjs(oddsDateValue).format('DDMMYYYY'));
        navigate("/tabs", { state: { admin: admin, link: "odds" } });
        // navigate('/oddsAdmin', { state: { oddsDateValue: dayjs(oddsDateValue).format('DDMMYYYY') } });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const divStyle = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'black'
    };




    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = (value) => {
        setIndex(value);
        setDialogOpen(true);
    };

    const handleDialogCloseD = () => {
        setDialogOpen(false);
    };

    return (
        <>


            <div>
                {dialogOpen && <PaymentDialogPhonePe open={dialogOpen} onClose={handleDialogCloseD} index={index} />}
                {/* Your main application content */}
                {/* <button onClick={handleDialogOpen}>Open Payment Dialog</button> */}
                {/* <PaymentDialog jsonData={jsonData} open={dialogOpen} onClose={handleDialogCloseD} /> */}
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3vh' }}>

                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    {superAdmin ?
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>Create/Change ODDS Data</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" startIcon={<FormatListBulletedIcon />} onClick={toggleDialog}>Add ODDS Data</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" startIcon={<GroupIcon />} onClick={() => navigate("/users")}>Users</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" startIcon={<BedroomBabyIcon />} onClick={() => navigate("/horse")}>Horse</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" style={{ backgroundColor: 'gold' }} startIcon={<CurrencyRupeeIcon style={{ color: 'black' }} />} onClick={() => navigate("/payments")}>Payments</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" startIcon={<AddLinkIcon />} onClick={() => navigate("/links")}>Links</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" startIcon={<TableViewIcon />} onClick={() => navigate("/data")}>Data</Button>
                            </Grid>
                        </Grid> : admin ? <Grid item>
                            <Button variant="contained" startIcon={<BedroomBabyIcon />} onClick={() => navigate("/horse")}>Horse</Button>
                        </Grid> : ''
                    }

                    <MyDialog open={isDialogOpen} onClose={toggleDialog} />
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Add Odds</DialogTitle>
                        <DialogContent>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Odds Name"
                                        value={oddsDateValue}
                                        format="DD/MM/YYYY"
                                        onChange={(newValue) => setOddsDateValue(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSave}>Save</Button>
                            <Button onClick={handleDialogClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                        <Alert severity="error" onClose={handleSnackbarClose}>
                            Please enter a value.
                        </Alert>
                    </Snackbar>
                    <div style={divStyle}>
                        *This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk
                    </div>


                    <TabNavHome rowData={rowData} columnDefs={admin ? columnDefs : columnDefsUser} />
                </div>
            </div>

        </>
    );
};

export default Home;
