import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Button, Grid, Snackbar, TextareaAutosize } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Input } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useState, useRef } from 'react';
import React, { useEffect } from 'react';
import ExcelManager from './ExcelManager';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';
import { collection, getDocs, setDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import ColorComponent from './ColorComponent';
import { ref, uploadBytes } from "firebase/storage";
import { storage } from './firebase';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Switch from '@mui/material/Switch';

const DataManagement = () => {
    const navigate = useNavigate();
    const [selectedFilePre, setSelectedFilePre] = useState(null);
    const [textValue, setTextValue] = useState('');
    const gridRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [rowDataPaddockOpinion, setRowDataPaddockOpinion] = useState([{ paddockOpinion: '' },
    { paddockOpinion: 'Not Shown' },
    { paddockOpinion: 'Superior Class' },
    { paddockOpinion: 'Winner in the paddock' },
    { paddockOpinion: 'Outstanding looking' },
    { paddockOpinion: 'Early to Starting Gate' },
    { paddockOpinion: 'Average' },
    { paddockOpinion: 'Poor' },
    { paddockOpinion: 'Good' },
    { paddockOpinion: 'Lesser than 400 kg' },
    { paddockOpinion: '' },
    { paddockOpinion: '' },
    { paddockOpinion: '' },
    { paddockOpinion: '' },
    ]);

    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [message, setMessage] = React.useState();

    const handleTextChange = (event) => {
        setTextValue(event.target.value);
    };

    const [state, setState] = useState({});

    const handleChange = (event) => {
        const newState = { ...state, [event.target.name]: event.target.checked };
        setState(newState);
        updateFirestore(newState);
    }

    const updateFirestore = async (newState) => {
        try {
            const firebaseDataName = "0buttonswitch";
            const horseRef = doc(collection(db, firebaseDataName), 'default');
            await setDoc(horseRef, newState);
        } catch (error) {
            console.error('Error updating Firestore:', error);
        }
    };

    // useEffect(() => {

    //     const firebaseDataName = "0buttonswitch";
    //     const horseRef = doc(collection(db, firebaseDataName), 'default');
    //     setDoc(horseRef, state);
    // }, [state])

    const columnDefs = [
        { headerName: 'Col-1', field: 'col1', editable: true, flex: 1 },
        { headerName: 'Col-2', field: 'col2', editable: true, flex: 1 },
        { headerName: 'Col-3', field: 'col3', editable: true, flex: 1 },
        { headerName: 'Col-4', field: 'col4', editable: true, flex: 1 },
        { headerName: 'Col-5', field: 'col5', editable: true, flex: 1 },
        { headerName: 'Col-6', field: 'col6', editable: true, flex: 1 },
        { headerName: 'Col-7', field: 'col7', editable: true, flex: 1 },

    ]

    const handleColorChangeForHorse = (params, newValue) => {
        const updatedRowData = rowDataPaddockOpinion.map((row) => {
            if (row.paddockOpinion === params.data.paddockOpinion && row.paddockOpinion === params.data.paddockOpinion) {
                return { ...row, paddockColor: newValue };
            }
            return row;
        });
        setRowDataPaddockOpinion(updatedRowData);
    };

    const columnDefsPaddock = [
        {
            headerName: 'Paddock Opinion',
            field: 'paddockOpinion',
            editable: true,
            flex: 1
        },
        {
            headerName: 'Paddock Color',
            field: 'paddockColor',
            flex: 1,
            cellStyle: params => {
                const backgroundColor = params.data.paddockColor;
                return { backgroundColor };
            },
            cellRendererFramework: (params) => (
                <ColorComponent
                    params={params}
                    onValueChange={(newValue) => handleColorChangeForHorse(params, newValue)}
                />
            )
        },
    ]

    useEffect(() => {
        const getData = async () => {
            let firebaseDataExists = 0;
            await getDocs(collection(db, "dropDetails"))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    newData.sort((a, b) => a.id - b.id);
                    setRowData(newData);
                    firebaseDataExists = newData.length;
                });
            if (firebaseDataExists) {
                setOpen(true);
                setSeverity("success")
                setMessage("Data Loaded Successfully")
            } else {
                setOpen(true);
                setSeverity("warning")
                setMessage("No Data Found")
            }
            await getDocs(collection(db, "paddockDetails"))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    newData.sort((a, b) => a.id - b.id);
                    setRowDataPaddockOpinion(newData);
                });


        };
        getData();
        const getSwitchData = async () => {
            const firebaseDataName = "0buttonswitch";
            const defaultDocRef = doc(db, firebaseDataName, 'default');

            try {
                const docSnapshot = await getDoc(defaultDocRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setState(prevState => ({ ...prevState, ...data }));
                    // console.log('Document data:', data);
                } else {
                    // console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        }

        getSwitchData();



    }, [])

    const handleAddRow = () => {

        const rows = textValue.split('\n').map((row) => {
            const [col1, col2, col3, col4, col5, col6, col7] = row.split('\t');

            return {
                col1: col1,
                col2: col2,
                col3: col3,
                col4: col4,
                col5: col5,
                col6: col6,
                col7: col7
            };
        }).filter((row) => Object.values(row).every(value => value !== undefined));

        if (!rows.length) {
            setOpen(true);
            setSeverity("error")
            setMessage("No data found please paste some data in textbox")
            return
        }
        const newRowData = [...rowData, ...rows];
        setRowData(newRowData);
        setTextValue('');
        setOpen(true);
        setSeverity("success")
        setMessage("Excel data has been imported to table")
        setDialogOpen(false);

    };

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const handleOpen = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const clearAllRowData = async () => {
        if (gridRef.current) {
            gridRef.current.setRowData([]);
        }
    };

    const handleSave = async () => {
        rowData.forEach(async (obj, index) => {
            const firebaseDataName = "dropDetails";
            const horseRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(horseRef, obj);
        });
        setOpen(true);
        setMessage("Data Saved Successfully in Database");
    };

    const handleSavePaddockOpinion = async () => {

        rowDataPaddockOpinion.forEach(async (obj, index) => {
            const firebaseDataName = "paddockDetails";
            const horseRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(horseRef, obj);
        });
        setOpen(true);
        setMessage("Data Saved Successfully in Database");

    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

    const handleFileChangePre = (event) => {
        setSelectedFilePre(event.target.files[0]);
    };

    const handleUploadPre = async (language) => {
        const filenamefordb = language;
        const fileExtension = selectedFilePre?.name?.split('.').pop();
        const fileName = `${filenamefordb}.${fileExtension}`;
        const storageRef = ref(storage, 'audio/' + fileName);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, selectedFilePre).then((snapshot) => {
            setOpen(true);
            setSeverity("success")
            setMessage("File Upload Successfully")

        });
    };

    return (
        <>
            <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate("/home")} style={{ margin: '10px' }}>
                Back
            </Button>
            <Grid container direction="row" spacing={2} style={{ border: '1px solid green' }}>
                <Grid item xs={4}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={clearAllRowData}
                                style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                                startIcon={<DeleteForeverIcon />}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSave}
                                startIcon={<SaveIcon />}
                                size="small"
                                style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
                            >
                                Save
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handleOpen}>
                                Add
                            </Button>
                        </Grid>
                        <Grid item>
                            <div className="ag-theme-alpine" style={{ height: '50%', width: '100%' }}>
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    animateRows={true}
                                    domLayout="autoHeight"
                                />
                            </div>

                        </Grid>
                    </Grid>


                </Grid>
                <Grid item xs={4}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={clearAllRowData}
                                style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                                startIcon={<DeleteForeverIcon />}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSavePaddockOpinion}
                                startIcon={<SaveIcon />}
                                size="small"
                                style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <div className="ag-theme-alpine" style={{ height: '600px', width: '200px' }}>
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    rowData={rowDataPaddockOpinion}
                                    columnDefs={columnDefsPaddock}
                                    animateRows={true}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4} style={{ border: '1px solid black', width: '400px', padding: '10px', marginTop: '5px' }}>
                            ODDS
                            <Switch
                                checked={state?.switch1}
                                onChange={handleChange}
                                name="switch1"
                                inputProps={{ 'aria-label': 'Switch 1' }}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ border: '1px solid black', width: '400px', padding: '10px', marginTop: '5px' }}>
                            SELECTION
                            <Switch
                                checked={state?.switch2}
                                onChange={handleChange}
                                name="switch2"
                                inputProps={{ 'aria-label': 'Switch 2' }}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ border: '1px solid black', width: '400px', padding: '10px', marginTop: '5px' }}>
                            PADDOCK OPINION
                            <Switch
                                checked={state?.switch3}
                                onChange={handleChange}
                                name="switch3"
                                inputProps={{ 'aria-label': 'Switch 3' }}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ border: '1px solid black', width: '400px', padding: '10px', marginTop: '5px' }}>
                            FINAL JUDGEMENT
                            <Switch
                                checked={state?.switch4}
                                onChange={handleChange}
                                name="switch4"
                                inputProps={{ 'aria-label': 'Switch 4' }}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Add Excel Data</DialogTitle>
                <DialogContent>
                    <ExcelManager
                        handleAddRow={handleAddRow}
                        textValue={textValue}
                        handleTextChange={handleTextChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DataManagement;