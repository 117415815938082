import { React, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
// import TopBar from './TopBar';
import Login from './Login';
import Signup from './Signup';
import Home from './Home';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import MyContext from './MyContext';
import Users from './Users';
import OddsAdmin from './OddsAdmin';
import Odds from './Odds';
import OddsPaddockSelection from './OddsPaddockSelection';
import TabNavOdds from './TabNavOdds';
import OddsHorsePaddock from './OddsHorsePaddock.js';
import RaceHorseManagement from './RaceHorseManagement';
import BetManagement from './BetManagement';
import DataManagement from './DataManagement';
import PasswordReset from './PasswordReset';
import OtpVerify from './OtpVerify';
import Razorpay from './RazorPay';
import HomePage from './Pages/HomePage';
import RenderLayout from './RenderLayout';
import RefundPolicy from './Pages/RefundPolicy';
import TermsAndConditions from './Pages/TermsAndConditions.js';
import Privacy from './Pages/privacy.js';
import PaymentManagement from './PaymentManagement.js';
import { Links } from './Links.js';
import OddsPaddockSelectionPercentage from './OddsPaddockSelectionPercentage.js';

const NotFound = () => <h1>404 Not Found</h1>;
const NoOddsFound = () => <h1>No Odds Found</h1>;

function App() {
  const [sharedDataDate, setSharedDataDate] = useState('');
  const [sharedDataVenue, setSharedDataVenue] = useState('');
  const location = useLocation();
  let navigate = useNavigate();
  // useEffect(() => {
  //   const hostname = window.location.hostname;
  //   if (hostname !== 'ashwavaarte.com') {
  //     setTimeout(() => {
  //       // navigate(`https://ashwavaarte.com${location.pathname}`, { replace: true });
  //       window.location.href = `https://ashwavaarte.com${location.pathname}`;
  //     }, 3000);
  //     // alert(`Please visit the correct URL: https://ashwavaarte.com${location.pathname}`);
  //   }
  // }, []);

  return (

    <div>
      <MyContext.Provider value={{ sharedDataDate: [sharedDataDate, setSharedDataDate], sharedDataVenue: [sharedDataVenue, setSharedDataVenue] }}>
        {/* <TopBar /> */}
        <Routes>
          <Route element={<RenderLayout />}>
            <Route path="/tabs" element={<TabNavOdds />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset" element={<PasswordReset />} />
            <Route path="/otp" element={<OtpVerify />} />
            <Route path="/users" element={<Users />} />
            <Route path="/home" element={<Home />} />
            <Route path="/bet" element={<BetManagement />} />
            <Route path="/data" element={<DataManagement />} />
            <Route exact path="/oddsAdmin" element={<OddsAdmin />} />
            <Route exact path="/odds" element={<Odds />} />
            <Route exact path="/selection" element={<OddsPaddockSelection />} />
            <Route exact path="/paddock" element={<OddsHorsePaddock />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/horse" element={<RaceHorseManagement />} />
            <Route path="/no-odds" element={<NoOddsFound />} />
            <Route path="/pay" element={<Razorpay />} />
            <Route path="/refund-return-policy" element={<RefundPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/payments" element={<PaymentManagement />} />
            <Route path="/links" element={<Links />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/percentage" element={<OddsPaddockSelectionPercentage />} />
          </Route>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </MyContext.Provider>
    </div >

  );
}


export default App;
