import RecorderControls from "./components/recorder-controls";
import RecordingsList from "./components/recordings-list";
import useRecorder from "./hooks/useRecorder";
import "../App.css";

export default function Recorder({handleUploadPreRecorder,handleUploadPostRecorder,handleUploadPostPaddockRecorder,recorder}) {
  const { recorderState, ...handlers } = useRecorder();
  const { audio } = recorderState;

  return (
    <section className="voice-recorder">
      <h1 className="title">Race Recorder</h1>
      <div className="recorder-container">
        <RecorderControls recorderState={recorderState} handlers={handlers} />
        <RecordingsList audio={audio} 
        handleUploadPreRecorder={handleUploadPreRecorder} 
        handleUploadPostRecorder={handleUploadPostRecorder} 
        handleUploadPostPaddockRecorder = {handleUploadPostPaddockRecorder}
        recorder={recorder} />
      </div>
    </section>
  );
}
